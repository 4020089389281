import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { envVersionPath } from '../../lib/helper';

const CustomInputForUniqueness = ({ item }) => {
  const [formValue, setFormValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const versionPath = envVersionPath;

  const { key, name, type, comment, validation } = item;

  const onChange = e => {
    const val = e.target.value.toLowerCase();
    // const re = /^(?=[a-zA-Z0-9._]{3,15}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

    const re = /^[a-zA-Z0-9-]{3,15}$/;

    if (val.length < 3) {
      setFormValue(val);
      setIsLoading(false);
      setIsValid(false);
    }

    if (re.test(val)) {
      setFormValue(val);
      setIsLoading(true);
      setIsValid(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUsername = useCallback(
    debounce(async value => {
      if (value.length >= 3) {
        const ref = doc(firestore, `${versionPath}/companyNames/${value}`);
        const snap = await getDoc(ref);
        console.log('Firestore read executed!', snap.exists());
        setIsValid(!snap.exists());
        setIsLoading(false);
      }
    }, 500),
    [],
  );

  useEffect(() => {
    checkUsername(formValue);
  }, [checkUsername, formValue]);

  useEffect(() => {
    if (formValue && isValid) {
      // 条件を満たしたらreact-hook-formにデータを登録、エラーも場合はクリア
      setValue(key, formValue);
      clearErrors(key);
    }
  }, [clearErrors, formValue, isValid, key, setValue]);

  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {name}
        {validation.required && (
          <span className="ml-2 text-xs text-snRed">※必須</span>
        )}
      </label>
      <div>
        <p className="mt-1 text-xs text-gray-500">{comment}</p>
      </div>
      <div className="mt-1">
        <input
          type={type}
          name={key}
          value={formValue}
          onChange={onChange}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        />
      </div>
      {errors[key] && (
        <span className="text-sm font-bold text-snRed">
          {errors[key].message}
        </span>
      )}
      <UsernameMessage
        value={formValue}
        isValid={isValid}
        isLoading={isLoading}
      />
    </div>
  );
};

const UsernameMessage = ({ value, isValid, isLoading }) => {
  if (isLoading) {
    return <p className="mt-1 text-xs text-gray-500">確認中...</p>;
  }

  if (isValid) {
    return <p className="mt-1 text-xs text-snGreen">{value}は使用できます。</p>;
  }
  if (value && !isValid) {
    return (
      <p className="mt-1 text-xs text-snRed">この名前は使用できません。</p>
    );
  }
  return <p />;
};

export default CustomInputForUniqueness;
