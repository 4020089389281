import { Dialog } from '@headlessui/react';
import { useModalSettings } from '../../context/modalSettings.context';
import ModalFlame from './ModalFlame';
import Register from './SignInOrRegister/Register';
import SignIn from './SignInOrRegister/SignIn';
import SignInOrRegister from './SignInOrRegister/SignInOrRegister';

const Modal = () => {
  const { modalType } = useModalSettings();

  const renderContent = () => {
    if (modalType === 'company-sign-in-or-register') {
      return (
        <>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            企業の方へ
          </Dialog.Title>
          <SignInOrRegister type="company" />
        </>
      );
    }

    if (modalType === 'company-sign-in') {
      return (
        <>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            企業サインイン
          </Dialog.Title>
          <SignIn type="company" />
        </>
      );
    }

    if (modalType === 'company-register') {
      return (
        <>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            企業登録
          </Dialog.Title>
          <Register type="company" />
        </>
      );
    }

    return null;
  };

  return <ModalFlame>{renderContent()}</ModalFlame>;
};

export default Modal;
