import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../lib/firebase';

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const [authObj, setAuthObj] = useState(null);

  useEffect(() => {
    const authUnsub = onAuthStateChanged(auth, async authData => {
      if (authData) {
        setAuthObj(authData);
      } else {
        setAuthObj('none');
      }
    });

    return () => {
      authUnsub();
      setAuthObj(null);
    };
  }, []);

  const value = useMemo(
    () => ({
      authObj,
      setAuthObj,
    }),
    [authObj],
  );

  return (
    <UserAuthContext.Provider value={value}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext);
