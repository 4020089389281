import { createContext, useState, useContext, useEffect } from 'react';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { useRouter } from 'next/router';
import { firestore } from '../../lib/firebase';
import { envVersionPath, postToJSON } from '../../lib/helper';

const CompanyProfileContext = createContext();

export const CompanyProfileProvider = ({ children }) => {
  const [companyProfile, setCompanyProfile] = useState();
  const router = useRouter();
  const versionPath = envVersionPath;

  const {
    query: { company },
  } = router;

  useEffect(() => {
    let unsub;
    if (company) {
      const companyDocs = collection(
        firestore,
        `${versionPath}/privateCompanies`,
      );
      const companyQuery = query(
        companyDocs,
        where('companyUrlName', '==', company),
        where('withActive', '==', true),
      );

      unsub = onSnapshot(
        companyQuery,
        snap => {
          const { docs } = snap;
          if (docs.length) {
            console.log('companyProfile', postToJSON(docs[0]));
            setCompanyProfile(postToJSON(docs[0]));
          }
        },
        error => {
          console.error(error);
        },
      );
    } else {
      setCompanyProfile({});
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [company, versionPath]);

  if (!companyProfile) return null;

  return (
    <CompanyProfileContext.Provider value={companyProfile}>
      {children}
    </CompanyProfileContext.Provider>
  );
};

export const useCompanyProfile = () => useContext(CompanyProfileContext);
