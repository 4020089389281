import { useModalSettings } from '../../../context/modalSettings.context';

const SignInOrRegister = ({ type }) => {
  const { setModalType } = useModalSettings();

  return (
    <div className="mt-6 space-y-4 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl">
      <div className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
        <div className="p-6">
          <p className="mt-4 text-sm text-gray-500">
            サブノートに登録済みの方はこちらからお進みください。
          </p>
          <button
            type="button"
            onClick={() => {
              setModalType(`${type}-sign-in`);
            }}
            className="mt-8 block w-full rounded-md border border-snBlueDark bg-snBlue py-2 text-center text-sm font-semibold text-white hover:bg-snBlueDark"
          >
            サインイン
          </button>
        </div>
      </div>

      <div className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
        <div className="p-6">
          <p className="mt-4 text-sm text-gray-500">
            これからサブノートに登録する方はこちらからお進みください。
          </p>
          <button
            type="button"
            onClick={() => {
              setModalType(`${type}-register`);
            }}
            className="mt-8 block w-full rounded-md border border-snBlueDark bg-snBlue py-2 text-center text-sm font-semibold text-white hover:bg-snBlueDark"
          >
            登録
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInOrRegister;
