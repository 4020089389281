import {
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { auth } from '../../../lib/firebase';
import RenderInputs from '../../tools/RenderInputs';
import { useModalSettings } from '../../../context/modalSettings.context';
import ButtonWithLoading from '../../ui/ButtonWithLoading';
import { signInOrRegisterFormItems } from '../../../data/signInOrRegisterFormItems';

const SignIn = ({ type }) => {
  const { close } = useModalSettings();

  const methods = useForm({
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [emailForReset, setEmailForReset] = useState();
  const router = useRouter();

  const items = signInOrRegisterFormItems(`${type}SignIn`);

  const onInputChange = useCallback(e => {
    setEmailForReset(e.target.value);
  }, []);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { email, password } = data;

      await signInWithEmailAndPassword(auth, email, password).then(
        async current => {
          if (!current.user.emailVerified) {
            await sendEmailVerification(auth.currentUser);
            toast(
              'メールアドレスの認証が必要です。確認メールをお送りしましたので、記載されているURLをクリックしてください。',
              { icon: '📩', duration: 12000 },
            );
          } else {
            toast.success('サインインしました。');
            return router.push('/auth/redirect');
          }
        },
      );

      setIsLoading(false);
      return close();
    } catch (error) {
      console.error(error);
      setIsLoading(false);

      if (error.message.includes('too-many-requests'))
        return toast.error(
          'エラーが発生しました。少し時間を置いて再度お試しください。',
          { duration: 4000 },
        );

      return toast.error(
        'メールアドレス、またはパスワードが間違っています。内容を確認の上、再度お試しください。',
        { duration: 6000 },
      );
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <FormProvider {...methods}>
          <form className="space-y-6" onSubmit={methods.handleSubmit(onSubmit)}>
            <RenderInputs items={items} />

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-snBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-snBlueDark focus:outline-none focus:ring-2 focus:ring-snBlueLight focus:ring-offset-2"
                disabled={isLoading}
              >
                <ButtonWithLoading text="サインイン" isLoading={isLoading} />
              </button>
              <div className="mt-2 text-sm">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="over:bg-purple-200 flex w-full justify-between rounded-lg px-4 py-4 text-left text-sm font-medium text-gray-900 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span>パスワード忘れた方はこちら</span>
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pb-2 text-sm text-gray-700">
                        <div className="mb-3 space-y-3 px-2 text-base">
                          <p className="px-2 text-sm">
                            以下の欄にメールアドレスを記入してパスワードリセットボタンをクリックしてください。再設定用のメールを送信いたします。
                          </p>

                          <div className="mt-1">
                            <input
                              value={emailForReset || ''}
                              onChange={onInputChange}
                              name="email"
                              type="email"
                              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={async () => {
                              if (
                                !emailForReset ||
                                emailForReset.trim() === ''
                              ) {
                                return toast.error(
                                  'メールアドレスが入力されていません。',
                                );
                              }

                              try {
                                await sendPasswordResetEmail(
                                  auth,
                                  emailForReset,
                                );

                                toast.success('メールを送信しました。');
                                setEmailForReset();

                                return close();
                              } catch (error) {
                                console.error(error);
                                if (error.message.includes('invalid-email'))
                                  return toast.error(
                                    'メールアドレスが有効な形式ではありません。',
                                    { duration: 4000 },
                                  );

                                if (error.message.includes('user-not-found'))
                                  return toast.error(
                                    'このメールアドレスで作成されたアカウントは存在しません。',
                                    { duration: 4000 },
                                  );

                                return toast.error(
                                  '登録に失敗しました。内容を確認の上、再度お試しください。',
                                );
                              }
                            }}
                            className="flex w-full justify-center rounded-md border border-transparent bg-snRed py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-snBlueLight focus:ring-offset-2"
                          >
                            パスワードリセット
                          </button>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default SignIn;
