import { useCallback, useEffect, useState, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { MinusSmIcon, PlusSmIcon, XIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import ImageUploader from './ImageUploader';
import { firestore } from '../../lib/firebase';
import { envVersionPath, postToJSON } from '../../lib/helper';
import {
  recommendTagCategories,
  recommentTags,
} from '../../data/recommendTags';

const CustomInput = ({ item, isLoading, setIsLoading }) => {
  const { model, key, name, type, comment, commentUrl, validation, rows } =
    item;

  const {
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const renderInput = () => {
    if (model === 'text') {
      return (
        <div>
          <label
            htmlFor={key}
            className="block text-sm font-medium text-gray-700"
          >
            {name}
            {validation.required && (
              <span className="ml-2 text-xs text-snRed">※必須</span>
            )}
          </label>
          <div>
            <p className="mt-1 text-xs text-gray-500">{comment}</p>
          </div>
          <div className="mt-1">
            <input
              type={type}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              {...register(key, validation)}
            />
          </div>
          {errors[key] && (
            <span className="text-sm font-bold text-snRed">
              {errors[key].message}
            </span>
          )}
        </div>
      );
    }

    if (model === 'checkbox') {
      return (
        <div>
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id={key}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                {...register(key, validation)}
              />
            </div>

            <div className="ml-3 text-sm">
              <label htmlFor={key} className="font-medium text-gray-700">
                {name}
                {validation.required && (
                  <span className="ml-2 text-xs text-snRed">※必須</span>
                )}
              </label>
              <p className="text-gray-500">{comment}</p>
              {commentUrl && (
                <div className="mt-1">
                  <a
                    href={commentUrl.url}
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {commentUrl.text}
                  </a>
                </div>
              )}
            </div>
          </div>
          {errors[key] && (
            <span className="text-sm font-bold text-snRed">
              {errors[key].message}
            </span>
          )}
        </div>
      );
    }

    if (model === 'textarea') {
      const countOfChara = watch(key)?.length || 0;

      return (
        <div>
          <label
            htmlFor={key}
            className="block text-sm font-medium text-gray-700"
          >
            {name}
            {validation.required && (
              <span className="ml-2 text-xs text-snRed">※必須</span>
            )}
          </label>
          <div>
            <p className="mt-1 text-xs text-gray-500">{comment}</p>
          </div>
          <div className="mt-1">
            <textarea
              type={type}
              rows={rows}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              {...register(key, validation)}
            />
            {validation.maxLength && (
              <div className="w-full text-right">
                <p className="mt-1 text-xs text-gray-500">{countOfChara}文字</p>
              </div>
            )}
          </div>
          {errors[key] && (
            <span className="text-sm font-bold text-snRed">
              {errors[key].message}
            </span>
          )}
        </div>
      );
    }

    if (model === 'tag') {
      return (
        <div>
          <label
            htmlFor={key}
            className="block text-sm font-medium text-gray-700"
          >
            {name}
            {validation.required && (
              <span className="ml-2 text-xs text-snRed">※必須</span>
            )}
          </label>
          <div>
            <p className="mt-1 text-xs text-gray-500">{comment}</p>
          </div>
          <div className="mt-1">
            <TagMode item={item} setValue={setValue} getValues={getValues} />
          </div>
        </div>
      );
    }

    if (model === 'upload') {
      return (
        <div>
          <label
            htmlFor={key}
            className="block text-sm font-medium text-gray-700"
          >
            {name}
            {validation.required && (
              <span className="ml-2 text-xs text-snRed">※必須</span>
            )}
          </label>
          <div>
            <p className="mt-1 text-xs text-gray-500">{comment}</p>
          </div>
          <div className="mt-1">
            <ImageUploader
              item={item}
              setValue={setValue}
              watch={watch}
              validation={validation}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>
          {errors[key] && (
            <span className="text-sm font-bold text-snRed">
              {errors[key].message}
            </span>
          )}
        </div>
      );
    }

    return null;
  };

  return <>{renderInput()}</>;
};

export default CustomInput;

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const TagMode = ({ item, setValue, getValues }) => {
  const { key } = item;

  const [tagCandidate, setTagCandidate] = useState(getValues(key) || []);
  const [input, setInput] = useState('');
  const [popularTags, setPopularTags] = useState([]);
  const versionPath = envVersionPath;

  useEffect(() => {
    const tagsQuery = query(
      collection(firestore, `${versionPath}/tags`),
      orderBy('times', 'desc'),
      limit(30),
    );

    getDocs(tagsQuery).then(snapShot =>
      setPopularTags(snapShot.docs.map(postToJSON)),
    );
  }, [versionPath]);

  const setTag = () => {
    if (!input || input.trim() === '') {
      return null;
    }

    if (tagCandidate.length > 9) {
      return toast.error('キーワードは10個まで設定できます。');
    }

    if (tagCandidate.includes(input)) {
      return toast.error('すでに含まれているキーワードです。');
    }

    setTagCandidate(s => [...s, input]);
    setValue(key, [...tagCandidate, input]);
    setInput('');
  };

  const onChange = useCallback(e => {
    setInput(e.target.value);
  }, []);

  // エンターキーを押したら追加処理
  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setTag();
    }
  };

  return (
    <div>
      <input
        type="text"
        name={key}
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={input}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
      <p className="mt-1 text-xs text-snRed">
        ※設定方法：上のフィールドにキーワードを入力してエンターキーを押してください。
      </p>
      <div className="mt-1">
        {tagCandidate.map((tag, index) => {
          return (
            <div
              key={tag + index}
              className="mr-2 inline-flex items-center rounded-full bg-snBlue px-3 py-1 text-xs font-medium text-white"
            >
              {tag}
              <button
                type="button"
                onClick={() => {
                  setTagCandidate(s => [...s.filter(word => word !== tag)]);
                  setValue(key, [...tagCandidate.filter(word => word !== tag)]);
                }}
              >
                <XIcon className="ml-2 h-4 w-4 text-white" aria-hidden="true" />
              </button>
            </div>
          );
        })}
      </div>
      <div className="mt-3 rounded border-2 p-3">
        <p className="text-sm">
          他のユーザーが登録しているキーワード（上位30）（クリックすると追加されます）
        </p>
        {popularTags
          .filter(tag => {
            const { tagName } = tag;
            if (!tagCandidate.includes(tagName)) {
              return tag;
            }
            return null;
          })
          .map((tag, index) => {
            const { tagName, times } = tag;
            return (
              <button
                type="button"
                key={tagName + index}
                className="mr-2 mt-2 inline-flex items-center rounded-full border-2 border-snGreen px-4 py-1 text-sm font-medium"
                onClick={() => {
                  if (tagCandidate.length > 9) {
                    return toast.error('キーワードは10個まで設定できます。');
                  }

                  setTagCandidate(s => [...s, tagName]);
                  setValue(key, [...tagCandidate, tagName]);
                }}
              >
                {tagName}
                <span className="ml-2 scale-90">( {times} )</span>
              </button>
            );
          })}
      </div>
      <div className="mt-3 rounded border-2 p-3">
        <p className="mb-2 text-sm">政策関連キーワード</p>
        <RecommendTagButtons
          item={item}
          tagCandidate={tagCandidate}
          setTagCandidate={setTagCandidate}
          setValue={setValue}
        />
      </div>
    </div>
  );
};

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const RecommendTagButtons = ({
  item,
  tagCandidate,
  setTagCandidate,
  setValue,
}) => {
  const { key } = item;

  const [isShowing, setIsShowing] = useState(false);
  const [categoryKey, setCategoryKey] = useState();
  const [displayTags, setDisplayTags] = useState([]);

  const recommentTagsArray = recommentTags;
  const recommendTagCategoriesArray = recommendTagCategories;

  useEffect(() => {
    if (categoryKey) {
      setDisplayTags(recommentTagsArray[categoryKey]);
    }
  }, [categoryKey, recommentTagsArray]);

  return (
    <div className="flex flex-col items-center">
      {recommendTagCategoriesArray.map((data, index) => {
        const { categories } = data;
        return (
          <div
            className="relative z-0 mb-3 inline-flex rounded-md shadow-sm"
            key={index}
          >
            {categories.map((obj, ci) => {
              const { key: ckey, value } = obj;

              return (
                <button
                  key={ckey + ci}
                  type="button"
                  className={`relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 ${
                    ci === 0 && 'rounded-l-md'
                  } ${ci === 2 && 'rounded-r-md'}`}
                  onClick={() => {
                    setCategoryKey(ckey);
                    setIsShowing(true);
                  }}
                >
                  {value}

                  {categoryKey === ckey ? (
                    <MinusSmIcon
                      className="h-5 w-5 text-snBlue"
                      aria-hidden="true"
                    />
                  ) : (
                    <PlusSmIcon
                      className="h-5 w-5 text-snRed"
                      aria-hidden="true"
                    />
                  )}
                </button>
              );
            })}
          </div>
        );
      })}

      <div className="w-full px-6">
        <Transition
          as={Fragment}
          show={isShowing}
          enter="transition ease-in duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div className="grid w-full grid-cols-3">
            {displayTags.map((tagName, index) => {
              return (
                <div
                  key={index}
                  className="col-span-1 mb-3 flex items-center pr-1"
                >
                  <input
                    onChange={e => {
                      if (e.target.checked) {
                        if (tagCandidate.length > 9) {
                          return toast.error(
                            'キーワードは10個まで設定できます。',
                          );
                        }

                        setTagCandidate(s => [...s, tagName]);
                        setValue(key, [...tagCandidate, tagName]);
                      } else {
                        setTagCandidate(s => [
                          ...s.filter(word => word !== tagName),
                        ]);
                        setValue(key, [
                          ...tagCandidate.filter(word => word !== tagName),
                        ]);
                      }
                    }}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={tagCandidate.includes(tagName)}
                  />
                  <p className="ml-3 text-sm text-gray-600">{tagName}</p>
                </div>
              );
            })}
          </div>
        </Transition>
      </div>
    </div>
  );
};
