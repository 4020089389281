import React, { createContext, useState, useContext, useEffect } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { signOut } from 'firebase/auth';
import { useUserAuth } from './userAuth.context';
import { auth, firestore } from '../lib/firebase';
import { envVersionPath } from '../lib/helper';

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const { authObj } = useUserAuth();

  const [userProfile, setUserProfile] = useState({});
  const versionPath = envVersionPath;

  useEffect(() => {
    let unsub;
    if (authObj) {
      const ref = doc(firestore, `${versionPath}/users/${authObj.uid}`);
      console.log('uid', authObj.uid);

      unsub = onSnapshot(
        ref,
        snap => {
          if (snap.exists() && !userProfile.active) {
            console.log('userProfile', snap.data());
            if (snap.data().active) {
              setUserProfile(snap.data());
            } else {
              toast.error('このアカウントは利用できません。', {
                duration: 6000,
              });
              signOut(auth);
            }
          }
        },
        error => {
          console.error(error);
        },
      );
    } else {
      setUserProfile({});
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [authObj, userProfile.active, versionPath]);

  return (
    <UserProfileContext.Provider value={userProfile}>
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => useContext(UserProfileContext);
