export const serviceImageMaterials = [
  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F1_800x800.webp?alt=media&token=a2eb9b25-822c-4484-ad21-e4e47b2a47b0',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F2_800x800.webp?alt=media&token=92e6fffc-8fc3-47a6-869c-dd12e93430ba',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F3_800x800.webp?alt=media&token=d2c92149-aaa5-44ac-a7bb-e4ab8f3c311d',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F4_800x800.webp?alt=media&token=a8e2c44e-3958-40b5-abe3-b9180327e2fa',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F5_800x800.webp?alt=media&token=20bd9b38-753d-4fe5-85dd-f7f361bad139',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F6_800x800.webp?alt=media&token=cd84cd45-3fca-4ed4-92ea-29293fac9cdb',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F7_800x800.webp?alt=media&token=9e307436-a526-4b4f-8b6d-83b034a489b9',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F8_800x800.webp?alt=media&token=8ce65648-91d4-4995-af82-56c8d3f02f20',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F9_800x800.webp?alt=media&token=8e65bdd5-4843-4e26-89f7-929104ec7059',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F10_800x800.webp?alt=media&token=1af09b05-36c1-4880-b21b-81ea2610c562',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F11_800x800.webp?alt=media&token=84ae54d4-656d-4770-8a65-62612b7cfd0e',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F12_800x800.webp?alt=media&token=1ea24fdf-5cfb-4a52-b44c-de3b9118e233',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F13_800x800.webp?alt=media&token=ea51c398-40df-41b5-8754-170fd63e290d',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F14_800x800.webp?alt=media&token=8832ef5f-1102-4a0a-9ebe-a4d562aa7dd8',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F15_800x800.webp?alt=media&token=65084cda-d89f-44c4-aace-a575ae72af30',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F17_800x800.webp?alt=media&token=2ea88f94-f986-40aa-865b-695e6d238827',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F18_800x800.webp?alt=media&token=bacaf46f-ea88-4db8-9fb7-7461343b84db',

  'https://firebasestorage.googleapis.com/v0/b/sub-note-test.appspot.com/o/imagesToResize%2Fmaterial%2F16_800x800.webp?alt=media&token=6d73d49c-6611-4ddc-ba91-bde8d790848b',
];
