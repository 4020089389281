const ButtonWithLoading = ({ text, isLoading = false }) => {
  return isLoading ? (
    <div className="flex h-6 items-center justify-center">
      <div className="h-2 w-2 animate-ping rounded-full bg-white" />
      <div className="mx-4 h-2 w-2 animate-ping rounded-full bg-white" />
      <div className="h-2 w-2 animate-ping rounded-full bg-white" />
    </div>
  ) : (
    text
  );
};

export default ButtonWithLoading;
