export const recommendTagCategories = [
  {
    index: 0,
    categories: [
      { key: 'enviroment', value: '環境・災害・資源' },
      { key: 'building', value: '建設・交通・開発' },
      { key: 'region', value: '地域・医療・食品' },
    ],
  },
  {
    index: 1,
    categories: [
      { key: 'economy', value: '経済・産業・雇用' },
      { key: 'crime', value: '防犯・外交・国際' },
      { key: 'history', value: '歴史・文化・教育' },
    ],
  },
];

export const recommentTags = {
  enviroment: [
    '気候変動',
    '地球温暖化',
    '騒音',
    '土壌汚染',
    '環境保全',
    '大気汚染',
    '化学物質',
    '排出ガス',
    '持続可能性',
    '生物多様性',
    '悪臭',
    '水質汚染',
    '海洋ゴミ問題',
    'SDGs',
    '避難',
    '震災',
    '復興',
    '防災',
    '土砂災害',
    '災害',
    '液状化',
    '停電',
    '浸水',
    '地盤沈下',
    '治水',
    '二次災害',
    '原子力',
    '廃棄物',
    '再生可能エネルギー',
    '放射性物質',
    'リサイクル',
    '水問題',
    '電力自由化',
    'バイオマス',
    '森林資源',
    '省エネルギー',
    '水産資源',
    'ゼロ・カーボン',
  ],
  building: [
    '公共工事',
    '土木工事',
    '都市計画',
    'まちづくり',
    '道路保全',
    '公園',
    '整備',
    '住宅',
    '農地',
    '収用',
    '公共交通',
    '新幹線',
    'リニアモーターカー',
    'ドローン',
    '渋滞',
    '交通事故',
    '事故防止',
    '飲酒運転',
    '逆走',
    'Maas',
    '買い物難民',
  ],
  region: [
    '人口減少',
    '少子化',
    '高齢社会',
    '未婚',
    '晩婚',
    '核家族',
    '晩産',
    '地方創生',
    'ボランティア活動',
    '空き家問題',
    '地域活性化',
    '過疎化',
    '空洞化',
    '空き店舗問題',
    '都市開発',
    '地方財政不足',
    '長寿命化',
    'がん検診',
    '認知症対策',
    '感染症対策',
    'コロナ対策',
    '精神疾患',
    '老老介護',
    '介護保険',
    '医療人材不足',
    '介護人材不足',
    '医師不足',
    '受動喫煙',
    '衛生管理',
    '生活習慣病',
    '健康寿命',
    '難病',
    '肺炎',
    'バリアフリー',
    '障がい',
    '花粉症',
    '自殺',
    'メンタルヘルス',
    '依存症',
    '食中毒',
    '食品安全',
    '食育',
    '機能性食品',
    'HACCP',
    '輸出拡大',
    'ブランディング',
  ],
  economy: [
    '老朽化',
    '観光振興',
    'インバウンド',
    '感染対策',
    'アフターコロナ',
    'デジタル化',
    '知的財産',
    '後継者問題',
    '事業承継',
    '生産性向上',
    '風評被害',
    '中小・ベンチャー企業支援',
    '農林水産業',
    '宿泊施設不足',
    '農業生産',
    '長時間労働',
    'UIターン',
    '非正規雇用',
    '過労死',
    '人材不足',
    '女性活躍',
    '外国人労働者',
    '男女共同参画',
    '就労支援',
    '強制労働',
    'ワークライフバランス',
    '労働災害',
    '失業対策',
    'ハラスメント',
    'セクシャルハラスメント',
    'パワーハラスメント',
    'マタニティ・ハラスメント',
    '貧困',
    '景気対策',
    '不良債権',
    '低所得者',
    '不正受給',
    '補助金',
    '助成金',
    '所得格差',
    'IT',
    '自動化',
    'DX',
    'AI',
    'ビッグデータ',
    '不正アクセス',
    'セキュリティ',
    '個人情報保護',
    'マイナンバー',
    'IoT',
    'Society 5.0',
  ],
  crime: [
    'テロ',
    '防犯',
    '暴力',
    '組織犯罪',
    '児童虐待',
    'DV',
    '薬物乱用',
    '性犯罪',
    '盗聴',
    'ストーカー',
    '性的虐待',
    '国際テロ',
    '自由貿易',
    'グローバル化',
    '領土問題',
    '国際競争力',
    '国際会議',
    '通訳',
  ],
  history: [
    '世界遺産',
    '文化財保護',
    '無形文化遺産',
    '伝統工芸',
    '職人',
    '祭り',
    '民族',
    '民謡',
    '待機児童',
    'いじめ問題',
    '子育て育児支援',
    '児童虐待',
    '非行',
    '学力低下',
    '里親支援',
    '自立支援',
    '市民スポーツ',
    'スポーツ交流',
    'ニート',
    '引きこもり',
    '就職氷河期',
    'ドーピング',
    '体力テスト',
    'IT教育',
  ],
};
