export const envVersionPath = `${process.env.NEXT_PUBLIC_SYSTEM_DOMAIN}/version${process.env.NEXT_PUBLIC_SYSTEM_VERSION}`;

export function postToJSON(snap) {
  const data = snap?.data() || {};

  Object.keys(data).forEach(key => {
    if (key.includes('At') && typeof data[key] !== 'number') {
      data[key] = data[key]?.toMillis() || 0;
    }
  });

  return data;
}

export function today() {
  const now = new Date();

  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();

  return `${year}-${month}-${day}`;
}
