import { Toaster } from 'react-hot-toast';
import Modal from '../components/modal/Modal';
import GoogleAnalytics from '../components/tools/GoogleAnalytics';
import { ModalSettingsProvider } from '../context/modalSettings.context';
import { UserAuthProvider } from '../context/userAuth.context';
import { UserProfileProvider } from '../context/userProfile.context';
import usePageView from '../lib/custom-hooks';
import '../styles/globals.scss';

const MyApp = ({ Component, pageProps }) => {
  usePageView();

  let host;
  if (typeof window !== 'undefined') {
    host = window && window.location.hostname;
  }

  if (process.env.NODE_ENV === 'production' && host !== 'test.sub-note.com') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  const getLayout = Component.getLayout || (page => page);

  return (
    <UserAuthProvider>
      <UserProfileProvider>
        <ModalSettingsProvider>
          <GoogleAnalytics />
          {getLayout(<Component {...pageProps} />)}
          <Modal />
          <Toaster />
        </ModalSettingsProvider>
      </UserProfileProvider>
    </UserAuthProvider>
  );
};

export default MyApp;
