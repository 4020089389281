import React, { createContext, useContext, useMemo, useState } from 'react';
import { useModalState } from '../lib/custom-hooks';

const ModalSettingsContext = createContext();

export const ModalSettingsProvider = ({ children }) => {
  const { open, close, isOpen } = useModalState();
  const [modalType, setModalType] = useState();

  const value = useMemo(
    () => ({
      open,
      close,
      isOpen,
      modalType,
      setModalType,
    }),
    [close, isOpen, modalType, open],
  );

  return (
    <ModalSettingsContext.Provider value={value}>
      {children}
    </ModalSettingsContext.Provider>
  );
};

export const useModalSettings = () => useContext(ModalSettingsContext);
