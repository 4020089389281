import { useState } from 'react';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import {
  collection,
  doc,
  serverTimestamp,
  writeBatch,
} from 'firebase/firestore';
import { useForm, FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { auth, firestore } from '../../../lib/firebase';
import RenderInputs from '../../tools/RenderInputs';
import { useModalSettings } from '../../../context/modalSettings.context';
import ButtonWithLoading from '../../ui/ButtonWithLoading';
import { envVersionPath } from '../../../lib/helper';
import { signInOrRegisterFormItems } from '../../../data/signInOrRegisterFormItems';

const Register = ({ type }) => {
  const { close } = useModalSettings();

  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const items = signInOrRegisterFormItems(`${type}Register`);
  const versionPath = envVersionPath;

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setIsLoading(true);

    const { email, password, passwordRe, companyName, companyUrlName } = data;

    let withError = false;

    items.forEach(item => {
      const { model, key, validation } = item;

      // uniquenessはuseFormContextのregisterを使ってないので、個別にエラーチェック
      if (model === 'uniqueness' && validation.required && !data[key]) {
        setIsLoading(false);
        methods.setError(key, {
          type: 'manual',
          message: 'この項目は必須です。',
        });
        withError = true;
      }
    });

    if (password !== passwordRe) {
      setIsLoading(false);
      methods.setError('passwordRe', {
        type: 'manual',
        message: 'パスワードが一致しません',
      });
      withError = true;
    }

    if (withError) {
      return null;
    }

    console.log('data', data);

    try {
      // まずuserAuthentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const { uid } = userCredential.user;

      delete data.email;
      delete data.password;
      delete data.passwordRe;

      await sendEmailVerification(auth.currentUser);

      const companyDoc = doc(
        collection(firestore, `${versionPath}/privateCompanies`),
      );

      const companyData = {
        companyUsers: [uid],
        companyId: companyDoc.id,
        contactEmail: email,
        ...data,
        postInDraft: 0,
        postInOpen: 0,
        postInReview: 0,
        withProfileOpen: false,
        withProfileFirstOpen: false,
        withProfileDraft: false,
        withProfileDraftInReview: false,
        withPaid: false,
        withActive: true,
        serialNumberForPost: 1000,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const companyNameDoc = doc(
        firestore,
        `${versionPath}/companyNames/${companyUrlName}`,
      );

      const companyNameData = {
        companyId: companyDoc.id,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const userDoc = doc(firestore, `${versionPath}/users/${uid}`);

      const userData = {
        uid,
        email,
        type: 'companyUser',
        companyId: companyDoc.id,
        companyName,
        companyUrlName,
        active: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const batch = writeBatch(firestore);
      batch.set(companyDoc, companyData);
      batch.set(companyNameDoc, companyNameData);
      batch.set(userDoc, userData);

      await batch.commit();

      toast(
        'メールアドレスの認証が必要です。確認メールをお送りしましたので、記載されているURLをクリックしてください。',
        { icon: '📩', duration: 12000 },
      );

      setIsLoading(false);

      return close();
    } catch (error) {
      setIsLoading(false);
      console.error(error);

      if (error.message.includes('email-already-in-use'))
        return toast.error(
          'このメールアドレスは他のユーザーが使用しています。',
          { duration: 4000 },
        );

      return toast.error(
        '登録に失敗しました。内容を確認の上、再度お試しください。',
      );
    }
  };

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <FormProvider {...methods}>
          <form className="space-y-6" onSubmit={methods.handleSubmit(onSubmit)}>
            <RenderInputs items={items} />
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-snBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-snBlueDark focus:outline-none focus:ring-2 focus:ring-snBlueLight focus:ring-offset-2"
                disabled={isLoading}
              >
                <ButtonWithLoading text="登録" isLoading={isLoading} />
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Register;
