export const signInOrRegisterFormItems = key => {
  switch (key) {
    case 'companyRegister':
      return [
        {
          model: 'text',
          key: 'email',
          name: 'メールアドレス',
          type: 'email',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
            pattern: {
              value:
                /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
              message: 'メールアドレスの形式が正しくありません。',
            },
          },
        },
        {
          model: 'text',
          key: 'password',
          name: 'パスワード',
          type: 'password',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
            minLength: {
              value: 8,
              message: 'パスワードは8文字以上で設定してください。',
            },
          },
        },
        {
          model: 'text',
          key: 'passwordRe',
          name: 'パスワード（確認用）',
          type: 'password',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
            minLength: {
              value: 8,
              message: 'パスワードは8文字以上で設定してください。',
            },
          },
        },
        {
          model: 'text',
          key: 'companyName',
          name: '会社名',
          type: 'text',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
          },
        },
        {
          model: 'uniqueness',
          key: 'companyUrlName',
          name: 'オリジナルURL(15文字以内)',
          type: 'text',
          comment:
            '外部のユーザーがあなたの企業ページを訪れたURL「sub-note.com/xxx」の「xxx」部分に表示される文字列を自由に設定することができます。外部のユーザーがわかりやすいように、企業名や企業名と関連のある英字表示を設定してください。 ※小文字の英数字の他、ハイフン（-）が使用可 ※一度設定した文字列は変更不可',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
          },
          // validation: {
          //   required: { value: true, message: 'この項目は必須です。' },
          //   validate: {
          //     uniqueness: async v =>
          //       (await checkUniqueness({
          //         value: v,
          //         path: `companyNames/${v}`,
          //       })) || 'この名前は使用できません。',
          //   },
          // },
        },
      ];
    case 'companySignIn':
      return [
        {
          model: 'text',
          key: 'email',
          name: 'メールアドレス',
          type: 'email',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
            pattern: {
              value:
                /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
              message: 'メールアドレスの形式が正しくありません。',
            },
          },
        },
        {
          model: 'text',
          key: 'password',
          name: 'パスワード',
          type: 'password',
          validation: {
            required: { value: true, message: 'この項目は必須です。' },
            minLength: {
              value: 8,
              message: 'パスワードは8文字以上です。',
            },
          },
        },
      ];
    default:
      throw new Error('unexpected key');
  }
};
