import CustomInputForUniqueness from './CustomInputForUniqueness';
import CustomInput from './CustomInput';

const RenderInputs = ({ items, isLoading, setIsLoading }) => {
  const renderItems = items.map((item, index) => {
    const { model } = item;

    if (model === 'uniqueness') {
      return <CustomInputForUniqueness item={item} key={index} />;
    }

    return (
      <CustomInput
        item={item}
        key={index}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    );
  });

  return <div className="space-y-6">{renderItems}</div>;
};

export default RenderInputs;
