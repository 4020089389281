import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { TrashIcon } from '@heroicons/react/outline';
import { useCompanyProfile } from '../../context/company/companyProfile.context';
import { storage } from '../../lib/firebase';
import { today } from '../../lib/helper';
import ButtonWithLoading from '../ui/ButtonWithLoading';
import { serviceImageMaterials } from '../../data/serviceImageMaterials';
import PopoverPanel from './PopoverPanel';

const ImageUploader = ({
  item,
  setValue,
  watch,
  validation,
  isLoading,
  setIsLoading,
}) => {
  const { key, size, withMaterials } = item;

  const companyProfile = useCompanyProfile();

  const [imageUrl, setImageUrl] = useState();

  const { companyUrlName } = companyProfile;

  const defaultImageUrl = watch(key);

  useEffect(() => {
    setImageUrl(defaultImageUrl || '');
    return () => setImageUrl('');
  }, [defaultImageUrl]);

  const changeUploadFile = async e => {
    e.preventDefault();
    const { files } = e.target;

    if (files === null || files.length === 0) {
      return toast.error('画像が選択されていません。');
    }

    function delay(t, v) {
      return new Promise(resolve => {
        setTimeout(resolve.bind(null, v), t);
      });
    }

    async function tryGetUrl(triesRemaining, resizedRef) {
      if (triesRemaining < 0) {
        throw new Error('画像のリサイズに失敗');
      }

      await getDownloadURL(resizedRef)
        .then(url => {
          setValue(key, url);
          return setImageUrl(url);
        })
        .catch(error => {
          switch (error.code) {
            case 'storage/object-not-found':
              return delay(2000).then(() => {
                return tryGetUrl(triesRemaining - 1, resizedRef);
              });
            default:
              console.error(error);
              return Promise.reject(error);
          }
        });
    }

    try {
      setIsLoading(true);
      toast.success('圧縮して保存しています。少々お待ちください。', {
        duration: 4000,
      });

      const now = new Date();
      const second = now.getSeconds();

      const path = `/imagesToResize/${key}/${companyUrlName}/${today()}_${second}_${files[0].name.replace(
        /\s+/g,
        '',
      )}`;

      const storageRef = ref(storage, path);

      const metadata = {
        cacheControl: 'public,max-age=300',
        contentType: files[0].type,
      };

      await uploadBytes(storageRef, files[0], metadata);

      const resizedPath = `/imagesToResize/${key}/${companyUrlName}/${today()}_${second}_${
        files[0].name.replace(/\s+/g, '').split('.').slice(0)[0]
      }${size}.webp`;

      const resizedRef = ref(storage, resizedPath);

      // リサイズ版の画像のURLを取得
      await tryGetUrl(5, resizedRef);

      e.target.value = '';

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return toast.error(
        '登録に失敗しました。内容を確認の上、再度お試しください。※ファイル名に.（ドット）が含まれる場合エラーになります。',
        { duration: 10000 },
      );
    }
  };

  return (
    <div>
      <div className="grid grid-cols-3 space-x-4">
        <div className={!withMaterials ? 'col-span-3' : 'col-span-2'}>
          <label htmlFor={`upload-button-${key}`}>
            <div className="flex cursor-pointer justify-center rounded-md bg-snGray p-2 text-gray-800">
              <ButtonWithLoading text="画像を選択" isLoading={isLoading} />
            </div>

            <input
              accept="image/*"
              className="hidden"
              name={key}
              id={`upload-button-${key}`}
              multiple
              type="file"
              onChange={changeUploadFile}
              disabled={isLoading}
            />
          </label>
        </div>
        {withMaterials && (
          <div className="col-span-1">
            <ImageMaterialsPopOver
              keyValue={key}
              setValue={setValue}
              setImageUrl={setImageUrl}
            />
          </div>
        )}
      </div>
      <div className="p-6 text-xs">
        <div className="flex items-center justify-between">
          <p>現在の画像{!imageUrl && '：なし'}</p>
          {!validation.required && imageUrl && (
            <button
              type="button"
              onClick={() => {
                setValue(key, '');
              }}
            >
              <TrashIcon className="h-4 w-auto" />
            </button>
          )}
        </div>
        {imageUrl && <img src={imageUrl} alt="登録画像 " />}
      </div>
    </div>
  );
};

export default ImageUploader;

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const ImageMaterialsPopOver = ({ keyValue, setValue, setImageUrl }) => {
  const images = serviceImageMaterials;

  return (
    <PopoverPanel
      bgColor="bg-snGray"
      buttonText="素材から選択"
      buttonTextColor="text-gray-800"
      className="w-full"
    >
      <div className="grid h-[500px] grid-cols-2 overflow-y-scroll">
        {images.map((imageUrl, index) => {
          return (
            <div className="col-span-1" key={index}>
              <button
                type="button"
                onClick={() => {
                  setValue(keyValue, imageUrl);
                  setImageUrl(imageUrl);
                }}
              >
                <img src={imageUrl} alt="登録画像 " />
              </button>
            </div>
          );
        })}
      </div>
    </PopoverPanel>
  );
};
